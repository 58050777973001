<template>
  <div class="home-page">
    <common-header></common-header>
    <div class="home-content">
      <!-- banner图片 -->
      <div class="home-banner">
        <!-- <img class="banner-font" src="../../assets/img/bannerFont.png" alt=""> -->
        <video :src="$movie+'/mp4/en/banner.mp4'" class="fullscreenVideo" autoplay="autoplay" :loop="false" muted="muted"></video>
        <div>Higher quality audio source</div>
        <div class="banner-mouse">
          <span></span>
        </div>
        <!-- <img class="banner-mouse" src="../../assets/img/mouse.gif" alt="" /> -->
      </div>
      <!-- 流行音乐 -->
      <div class="pop-music">
        <div class="pop-music-scroll" v-for="(item, index) in 1" :key="index">
          <div class="musci-cd">
            <img class="music-back-left" :src="$http+'/img/cdLeft.png'" alt="">
            <img class="music-back-right" :src="$http + '/img/cdRight.png'" alt="">
          </div>
          <div class="music-info">
            <img class="musci-back-title" src="../../assets/en/home/QinString.png" alt="">
            <div class="musci-introduce">Qin Strings condense producers’aesthetic taste in the sound color of pop music strings, which is an indispensable source of fine strings in pop music writing. In daily work, Qin strings can greatly improve the work efficiency of music produces.</div>
            <div class="musci-buying">
              <img class="musci-back-buy" @click="buyFunc" src="../../assets/en/home/Buy.png" alt="">
              <div @click="toDetail">
                <span>More about</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 创作历程 -->
      <div class="home-process">
        <img class="process-back" src="../../assets/en/home/process.png" alt="">
        <img class="process-carousel" :src="carouselLink" alt="">
        <div class="carousel-box">
          <div class="arrow-img arrow-left">
            <img src="../../assets/img/arrowLeft.png" alt="" srcset="" @click="arrowClick('left')">
          </div>
          <el-carousel ref="carouselRef" :interval="4000" type="card" height="176px" arrow='never' indicator-position='none' @change='setCarousel'>
            <el-carousel-item v-for="item in carouselData" :key="item">
              <div class="is-active-div">
                <img :src="item" alt="">
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="arrow-img arrow-right">
            <img src="../../assets/img/arrowRight.png" alt="" srcset="" @click="arrowClick('left')">
          </div>
        </div>
        <div class="process-introduce">
          This music source was sampled in Jintian Recording Studio, the largest orchestral studio in Beijing, China.During the production process, teacher Chen Qinyang, as the producer, supervised the studio throughout the whole process, spared no detail, striving for perfection, and fine-tuned every sample of legato to restore the sense of reality of string legato.
        </div>
      </div>
      <!-- 推荐试听 -->
      <div class="home-audition">
        <img class="audition-back" src="../../assets/en/home/audition.png" alt="">
        <!-- 音频 -->
        <div class="musci-online">
          <div class='musci-audio' v-for="(item, index) in audioData" :key="index">
            <musciAudio :nowmusic="item"></musciAudio>
          </div>
        </div>
        <!-- 视频 -->
        <div class="video-online">
          <div class='musci-video' v-for="(item, index) in videoData" :key="index">
            <img class="video-back" :src="item.pic" alt="">
            <img class="video-play" @click="playVideo(item)" src="../../assets/img/playBack.png" alt="">
            <div>{{item.name}}</div>
          </div>
        </div>
      </div> 
       <!--购买按钮  -->
      <div class="buy-now" v-if="scroll>=1550">
        <img class="process-back-buy" :style="{'top':'80vh'}" @click="buyFunc" :src="$http + '/en/home/buyCol.png'" alt="">
      </div>
    </div>
    <common-footer></common-footer>
    <video-outside v-if="videoDialog" :nowmusic="nowVideo" @closeVideo='closeVideo'></video-outside>
    <order-online v-if="orderDialog" ref="orderDialog" @closeOnline='closeOnline'></order-online>
  </div>
</template>

<script>
// 公共头部/底部组件
import commonFooter from "../../components/en/commonFooter.vue";
import commonHeader from "../../components/en/commonHeader.vue";
// 视频播放组件
import videoOutside from '../../components/videoOutside.vue'
// 音频播放组件
import musciAudio from '../../components/musciAudio.vue'
// 在线订单组件
import orderOnline from '../../components/en/orderOnline.vue'
// 轮播图地址
import carousel_1 from '../../assets/img/carousel_1.png'
import carousel_2 from '../../assets/img/carousel_2.png'
import carousel_3 from '../../assets/img/carousel_3.png'
import carousel_4 from '../../assets/img/carousel_4.png'
// 四个视频
// import video_1 from '../../assets/mp4/musci_movie1.mp4' //流行1
// import video_2 from '../../assets/mp4/musci_movie2.mp4' // 流行2
// import video_3 from '../../assets/mp4/chinese_style1.mp4' // 中国风1
// import video_4 from '../../assets/mp4/chinese_style2.mp4' // 中国风2
// 四个视频背景
import auditionBack2 from '../../assets/img/auditionBack2.png'
import auditionBack3 from '../../assets/img/auditionBack3.png'
import chineseBack1 from '../../assets/img/chineseBack1.png'
import chineseBack2 from '../../assets/img/chineseBack2.png'
export default {
  components: {
    commonFooter,
    commonHeader,
    musciAudio,
    videoOutside,
    orderOnline
  },
  data() {
    return {
      // 轮播图选中的大图
      carouselLink: carousel_4,
      // 轮播图数据
      carouselData: [
        carousel_4,
        carousel_1,
        carousel_2,
        carousel_3,
      ],
      // 音频数据
      audioData: [
        {
          link: require('../../assets/mp3/movie_audition.mp3'),
          pic: require('../../assets/img/moveBack.png'),
          name: 'Movie Music Snippet Audition'
        },
        {
          link: require('../../assets/mp3/music_audition.mp3'),
          pic: require('../../assets/img/auditionBack1.png'),
          name: 'Pop Music Demo 1'
        }
      ],
      videoDialog: false, // 视频弹窗的dialog
      // 视频数据
      videoData: [
        {
          link: this.$movie + '/mp4/musci_movie1.mp4',
          pic: auditionBack2,
          name: 'Pop Music Demo 2'
        },
        {
          link: this.$movie + '/mp4/musci_movie2.mp4',
          pic: auditionBack3,
          name: 'Pop Music Demo 3'
        },
        {
          link: this.$movie + '/mp4/chinese_style1.mp4',
          pic: chineseBack1,
          name: 'Chinese style 1'
        },
        {
          link: this.$movie + '/mp4/chinese_style3.mp4',
          pic: chineseBack2,
          name: 'Chinese style 2'
        },
      ],
      // 播放视频的数据
      nowVideo: {
        link: null,
        name: ''
      },
      scroll: 0, // 距离顶部的滚动距离
      scrollBottom: null, // 距离底部滚动距离
      orderDialog: false, // 在线购买的弹窗
    };
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
    if(this.$route.query.tradeNo){
      this.orderDialog = true
      // this.stopMove()
    }
  },
  destroyed(){
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    // 监听滚动
    handleScroll(){
      // 变量 scrollHeight 是滚动条的总高度
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // 变量 windowHeight 是可视区的高度
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        //或者使用document.querySelector('.class或者#id').scrollTop
        this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        this.scrollBottom = scrollHeight - windowHeight - this.scroll
    },
    // 了解更多
    toDetail(){
      this.$router.push({
        path: '/qy/en/detail'
      })
    },
    // 切换轮播图
    setCarousel(newIndex, oldIndex){
      this.carouselLink = this.carouselData[newIndex]
      console.log(oldIndex);
    },
    // 手动点击切换轮播图
    arrowClick(type){
      if (type === 'right') {
        this.$refs.carouselRef.next()
      } else {
        this.$refs.carouselRef.prev()
      }
    },
    // 播放视频
    playVideo(item){
      if(item.link){
        this.nowVideo = {
          link: item.link,
          name: item.name
        }
        this.videoDialog = true
        // this.stopMove()
      }else{
        this.$message.warning('当前选中试听暂无视频资料。')
      }
    },
    // 关闭视频
    closeVideo(){
      this.videoDialog = false
      // this.Move()
    },
    // dialog的关闭
    handleClose(done){
      this.$refs.videoRef.pause()
      done()
    },
    // 立即购买
    buyFunc(){
      this.orderDialog = true
      // this.stopMove()
    },
    closeOnline(){
      this.orderDialog = false
      // this.Move()
      if(this.$route.query.tradeNo){
        this.$router.push({
          path: this.$route.path
        })
      }
    },
    //停止页面滚动
    stopMove(){
      let m = function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
    },
    //开启页面滚动
    Move(){
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
    }
  },
};
</script>

<style scoped lang="scss">
.home-page {
  position: relative;
  width: 100%;
  height: 100%;
  .home-content {
    box-sizing: border-box;
    width: 100%;
    background: #000;
    // 顶部banner
    .home-banner {
      position: relative;
      width: 100%;
      height: 1080px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // background: url("../../assets/img/banner.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      .fullscreenVideo{
        width: 100%;
        height: 100%;
        object-fit: fill
      }
      
      .banner-font {
        width: 1101px;
        height: 286px;
        margin-top: 226px;
      }

      div{
        position: absolute;
        bottom: 23%;
        left: 50%;
        transform: translate(-50%, 0);
        height: 50px;
        font-size: 32px;
        font-family: PingFang SC;
        color: #FFFFFF;
        line-height: 50px;
      }

      .banner-mouse{
        width: 30px;
        height: 45px;
        border: 2px rgba(255,255,255,0.3) solid;
        border-radius: 50px;
        position: absolute;
        bottom: 14%;
        span{
          position: absolute;
          width: 3px;
          height: 7px;
          left: 14px;
          top: 7px;
          opacity: 1;
          transform: scale(1);
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          transition: all 0.5s;
          background: #FFFFFF;
          border-radius: 5px;
          animation: slidein 3s infinite;
          -webkit-animation: slidein 3s infinite;
          // animation-direction: alternate;
        }
        .slidein {
          -moz-animation-duration: 3s;
          -webkit-animation-duration: 3s;
          animation-duration: 3s;
          -moz-animation-name: slidein;
          -webkit-animation-name: slidein;
          animation-name: slidein;
          -moz-animation-iteration-count: 3;
          -webkit-animation-iteration-count: 3;
          animation-iteration-count: 3;
          -moz-animation-direction: alternate;
          -webkit-animation-direction: alternate;
          animation-direction: alternate;
        }

        @-moz-keyframes slidein {
          0% {
            margin-top: 0%;
            transform: scale(1);
          }

          65%{
            margin-top: 70%;
            transform: scale(1);
          }

          100% {
            margin-top: 80%;
            transform: scale(0);
          }
          
        }

        @-webkit-keyframes slidein {
          0% {
            margin-top: 0%;
            transform: scale(1);
          }
          65%{
            margin-top: 70%;
            transform: scale(1);
          }
          100% {
            margin-top: 80%;
            transform: scale(0);
          }
        }

        @keyframes slidein {
          0% {
            margin-top: 0%;
            transform: scale(1);
          }

          65%{
            margin-top: 70%;
            transform: scale(1);
          }

          100% {
            margin-top: 80%;
            transform: scale(0);
          }
        }
      }
      
    }
    // 流行音乐
    .pop-music{
      width: 100%;
      margin-top: 80px;
      // background: url("../../assets/img/halfCd.png");
      // background-size: 514px 544px;
      // background-repeat: no-repeat;
      // background-position: 1838px 92px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      .pop-music-scroll{
        display: flex;
      }
      .musci-cd{
        position: relative;
        display: flex;
        margin-left: 250px;
        .music-back-left{
          margin-top: 92px;
          width: 541px;
          height: 541px;
        }
        .music-back-right{
          position: absolute;
          top: 92px;
          left: 485px;
          width: 300px;
          height: 541px;
        }
      }
      .music-info{
        margin-left: 199px;
        .musci-back-title{
          width: 770px;
          height: 153px;
        }
        .musci-introduce{
          margin-top: 45px;
          margin-left: 110px;
          width: 610px;
          height: 240px;
          font-size: 24px;
          line-height: 48px;
          color: rgba(255,255,255,.8);
          text-align: left;
          // text-align: justify;
        }

        .musci-buying{
          width: 610px;
          margin-top: 115px;
          margin-left: 126px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .musci-back-buy{
            width: 264px;
            height: 70px;
            cursor: pointer;
          }
          div{
            margin-left: 73px;
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 28px;
            font-family: PingFang SC;
            color: #FFFFFF;
            line-height: 40px;
            cursor: pointer;
          }
        }
      }
    }
    // 创作历程
    .home-process{
      position: relative;
      margin-top: 154px;
      width: 100%;
      padding: 0 270px 0 270px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .process-back{
        width: 696px;
        height: 152px;
      }
      .process-carousel{
        margin: 67px 0 0 126px;
        width: 1128px;
        height: 451px;
        text-align: center;
      }
      .arrow-img{
        position: absolute;
        width: 60px;
        height: 60px;
        cursor: pointer;
        background: #7d7e7f;
        border-radius: 50%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .carousel-box{
        width: 100%;
        height: 100%;
        position: relative;
      }
      .arrow-right{
        right: -15px;
        bottom: 32%;
      }
      .arrow-left{
        left: -15px;
        bottom: 32%;
      }
      .el-carousel{
        margin-top: -5px;
        margin-left: 126px;
        width: 1128px;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .is-active-div{
        width: 100%;
        height: 100%;
      }
      .is-active{
        background: linear-gradient(rgba(255,255,255,.6), rgba(255,255,255,.4));;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px); 
        backdrop-filter: blur(10px); /* This is the fallback for Safari that doesn't support backdrop-filter */
        border-radius: 6px;
        padding: 10px;
        box-sizing: border-box;
        .is-active-div{
          width: 100%;
          height: 100%;
          border-radius: 4px;
          background: #e4e4e3;
          padding: 3px;
          box-sizing: border-box;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .process-introduce{
        margin-top: 40px;
        margin-left: 126px;
        width: 1128px;
        height: 162px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(255,255,255,1);
        line-height: 54px;
        text-align: left;
      }
      // 取消超出隐藏功能
      // ::v-deep .el-carousel--horizontal{
      //   overflow-x: visible !important;
      // }
      // 左箭头
      ::v-deep .el-carousel__arrow--left{
        top: 50%;
        left: 0px;
        font-size: 18px;
        color: #fbfbfb;
        background: #808182;
      }

      //右箭头
      ::v-deep .el-carousel__arrow--right{
        top: 50%;
        right: 0px;
        font-size: 18px;
        color: #fbfbfb;
        background: #808182
      }
    }
    // 推荐试听
    .home-audition{
      margin-top: 155px;
      text-align: left;
      padding: 0 250px 60px 252px;
      .audition-back{
        width: 696px;
        height: 152px;
        margin-bottom: 58px;
      }
      .musci-online{
        width: 1418px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .musci-audio{
          width: 697px;
          height: 274px;
          margin-bottom: 80px;
        }
      }
      .video-online{
        width: 1418px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .musci-video{
          position: relative;
          width: 697px;
          height: 428px;
          margin-bottom: 80px;
          .video-back{
            width: 100%;
            height: 389px;
          }
          .video-play{
            position: absolute;
            left: 50%;
            top: 50%;
            width: 60px;
            height: 60px;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
          div{
            margin-top: 6px;
            height: 33px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 33px;
            text-align: center;
          }
        }
      }
    }
    // 立即购买
    .process-back-buy{
      position: fixed;
      right: 42px;
      // bottom: 80px;
      width: 70px;
      height: 70px;
      cursor: pointer;
      z-index: 888;
    }
  }
  ::v-deep .el-dialog{
    border-radius: 12px;
  }
  ::v-deep .el-dialog__body{
    padding: 0px 0px 0px 0px;
    height: 368px;
  }
  ::v-deep .el-dialog__header{
    text-align: left;
    background: rgba(173, 167, 162, .3s);
    border-radius: 12px;
  }
}
</style>